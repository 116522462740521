.searchbar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  border: 1px solid #cccccc;
  //margin-top: 15px;

  @include media-breakpoint-up(lg) {
    width: 25%;
    margin-right: 0.5rem;
    margin-top: unset;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.searchbarInput {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;

  input {
    border: 0;
    width: 80%;
  }

  .btn {
    margin-left: auto;
  }

  .btn--search {
    display: inline-block;
    background: transparent;
    border: 0;
    width: 20%;
    height: 48px;

    @include media-breakpoint-up(lg) {
      width: 48px;
    }

    &:after {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url('../images/search.svg');
      background-size: 50%;
      background-position: center center;
      background-repeat: no-repeat;
      content: "";
      width: 100%;
      height: 100%;
      color: $color-black;
    }

    &:hover {
      &:after {
        color: darken($color-black, 10%);
      }
    }
  }
}

.searchbarResults {
  position: absolute;
  left: -1px;
  top: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  width: calc(100% + 2px);
  background: #fff;
  border: 1px solid $color-border;
  z-index: 99;

  .--loading {
    min-height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 2rem;
      height: 2rem;
      border: 0.25rem solid $color-border;
      border-top-color: transparent;
      border-radius: 99rem;
      animation: spin 1s cubic-bezier(0.84, 0.1, 0.15, 0.87) infinite;
    }
  }

  .--empty {
    padding: 0.75rem;
    transition: 0.2s ease;
    display: flex;

    &:hover {
      background-color: $color-background-tinted;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0.25rem 0;
  }

  .--products {
    li a {
      padding: 0.5rem 0.75rem;
      display: grid;
      grid-template-columns: 6rem auto;
      gap: 0.5rem;
      transition: 0.2s ease;

      &:hover {
        background-color: $color-background-tinted;
      }

      .image {
        width: 100%;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.125rem;
        }
      }

      & > div {
        display: flex;
        align-items: center;

        .tag {
          margin-left: 1rem;
        }
      }
    }
  }

  .--categories {
    li a {
      padding: 0.75rem;
      transition: 0.2s ease;
      display: flex;

      &:hover {
        background-color: $color-background-tinted;
      }
    }
  }
}