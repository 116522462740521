.productRule {
	display: flex;
	align-items: center;
	padding: 1rem 1rem 1rem 3rem;
	border-top: 1px solid $color-border-light;
	border-bottom: 1px solid $color-border-light;
	margin-bottom: -1px;

	.image {
		height: 3.5rem;
		width: 5rem;

		img {
			min-width: 35px;
			min-height: 50px;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			border-radius: 0.125rem;
		}
	}

	&.--cart {
		display: grid;
		grid-template-columns: 5.5rem auto auto 1fr auto;
		grid-template-rows: 1fr 1fr;
		gap: 0 1rem;

		.image,
		.price {
			grid-row: span 2;
		}

		.amount {
			justify-self: flex-end;
			grid-column: 4 / span 1;
			grid-row: span 2;
			margin-left: auto;
			margin-right: 1rem;
		}
		.price {
			grid-column: 5 / span 1;
			justify-content: flex-end;
			min-width: 100px;
		}

		.name {
			grid-row: span 1;
			font-weight: 600;

			small {
				display: none;
			}
		}

		.removeItem {
			grid-row: 2 / span 1;
			grid-column: 2 / span 1;
			color: $color-copy;
			font-size: 0.875rem;
			text-decoration: underline;

			&:hover {
				text-decoration: underline;
				text-decoration-style: dashed;
			}
		}

		@media screen and (max-width: 40rem) {
			grid-template-rows: initial;
			grid-template-columns: 3rem auto auto auto;

			.image {
				width: 3rem;
				height: 2rem;
			}

			.amount {
				margin-left: 0;
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
				grid-row: 2 / span 1;
				grid-column: 2 / span 1;
				justify-self: flex-start;

				input[type="number"] {
					min-height: 2rem;
					padding: 0.25rem 0.5rem;
					min-width: 5rem;
				}
			}

			.price {
				grid-column: 4 / span 1;
			}

			.removeItem {
				grid-row: 3 / span 1;
			}
		}

		@media screen and (max-width: 32rem) {
			padding: 1rem 0.5rem 1rem 2rem;
			grid-gap: 0 0.5rem;

			.tag {
				display: none;
			}

			// .price {
			//   flex-direction: column-reverse;
			// }
		}
	}
	&.--checkout {
		display: flex;
		align-items: center;

		a {
			&:first-child {
				margin-right: 20px;
			}
		}

		.image {
			display: flex;
			width: 35px;
			height: 50px;
		}

		.name {
			grid-row: span 1;
			font-weight: 500;
			font-size: 0.9375rem;
		}

		.tag {
			justify-self: flex-start;
			font-size: 0.75rem;
			padding: 0.125rem 0.25rem;
		}
	}
}

.productDeal {
	padding-left: 2rem;
	position: relative;

	@media screen and (max-width: 32rem) {
		padding-left: 1rem;
	}

	.productRule {
		padding-left: 1rem;
	}

	> .tag {
		left: 0;
		width: 2rem;
		height: 100%;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: 32rem) {
			font-size: 0.875rem;
			width: 1rem;
		}

		span {
			white-space: nowrap;
			text-align: center;
			transform: rotate(-90deg);
		}
	}
}

.checkout__reviewCart {
	.productRule {
		padding: 0.5rem;
	}
	.productDeal {
		padding-left: 0;
		& > .tag {
			display: none;
		}
	}
}

.shippingMethods {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));

	@media screen and (max-width: 32rem) {
		grid-template-columns: 1fr;
	}

	gap: 1.5rem;
	margin: 0;
	padding: 0;

	label {
		padding: 1rem;
		position: relative;
		border: 1px solid $color-border-light;
		display: grid;
		grid-template-columns: 1.5rem auto;
		gap: 0.25rem 0.75rem;
		cursor: pointer;
		transition: 0.2s ease;

		&:hover {
			border-color: $color-border;

			.radio:before {
				border-color: $color-border;
			}
		}
	}

	.radio {
		grid-row: span 2;
		width: 1.5rem;

		&:before {
			content: "";
			display: block;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 99rem;
			border: 1px solid $color-border-light;
			transition: 0.2s ease;
			background-size: 1rem;
			background-position: center 60%;
			background-repeat: no-repeat;
		}
	}

	.label {
		grid-column: 2 / span 1;
		align-self: baseline;
		font-weight: 500;
	}

	.description {
		grid-column: 2 / span 3;
		grid-row: 2 / span 1;
		color: $color-copy;
		font-size: 0.9735rem;

		svg {
			margin-right: 0.5rem;
		}
	}

	input[type="radio"] {
		display: none;

		&:checked ~ .radio:before {
			border-color: $color-primary;
			background: url("../images/icon-check-white.svg"), $color-primary;
			background-size: 1rem;
			background-position: center 60%;
			background-repeat: no-repeat;
		}
	}

	.tag.--price {
		background: $color-background-tinted;
		color: $color-black;
		justify-self: start;
		margin-left: 0.75rem;
	}
}

.cartTotals,
.checkout__totals {
	background: $color-background-alt;
	padding: 2rem;
	color: $color-copy;
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	font-size: 0.9375rem;

	.line {
		padding: 0.75rem 1rem;
		border-top: 1px solid #e0e0e0;
		border-bottom: 1px solid #e0e0e0;
		margin-bottom: -1px;
		width: 20rem;
		display: flex;
		flex-wrap: nowrap;

		> span:nth-child(1) {
			width: 13rem;
			flex-shrink: 1;
			flex-grow: 1;
			text-align: right;
		}

		> span:nth-child(2) {
			width: 4rem;
			flex-grow: 1;
			text-align: right;
		}

		&:first-child {
			border-top: 0;
		}

		&.--total {
			font-weight: 600;
			border-bottom: 0;
			margin-bottom: 0;
		}
	}
}

.shipping,
.payment {
	margin: 3rem 0 2rem;

	> h2 {
		font-size: 1.25rem;
	}
}

small.includes_tax {
	display: none;
}

.cart__footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 3rem;
	margin-top: 2.5rem;

	a:not(.btn) {
		text-decoration: underline;

		&:hover {
			text-decoration-style: dashed;
		}
	}
}

.cartTitle {
	margin: 2rem 0;
	font-size: 1.25rem;
}

#cartDrawer {
	position: fixed;
	touch-action: none;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
	pointer-events: none;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0);
		transition: 0.3s ease;
	}

	.inner {
		background: $color-background;
		position: absolute;
		padding: 2rem;
		overflow-y: auto;
		width: 32rem;
		max-width: calc(100vw - 1rem);
		transform: translateX(32rem);
		height: 100%;
		right: 0;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		transition: 0.2s cubic-bezier(0.65, 0, 0.35, 1);

		.hasScribblyLine {
			font-size: 2rem;

			&:after {
				top: 120%;
				width: 7rem;
				left: calc(50% - 3.5rem);
			}
		}

		& > a {
			margin-top: auto;
			width: 100%;
		}

		[data-close="#cartDrawer"] {
			position: absolute;
			top: 0;
			right: 0;
		}

		.productList {
			width: 100%;
		}

		.productRule {
			padding: 1rem;
			width: 100%;

			&.--cart {
				.name {
					small {
						display: inline-block;
					}
				}
			}
		}

		.productRule.--cart .amount {
			display: none;
		}
	}

	&.active {
		&:before {
			background: rgba(0, 0, 0, 0.5);
		}

		.inner {
			transform: translateX(0);
			transition: 0.3s cubic-bezier(0.22, 1, 0.36, 1);
			pointer-events: initial;
		}
	}
}

.about_paypal {
	display: none;
}
