$color-text: #000000;
$color-text-white: #ffffff;
$color-white: #ffffff;
$color-black: #000000;
$color-background-black: #101010;
$color-copy: #383838;
$color-primary: #009b66;
$color-background: #ffffff;
$color-background-tinted: #f5f5f5;
$color-background-alt: #f5f5f5;
$color-border: #d9dbe0;
$color-border-light: #e8e9eb;
$color-placeholder: #b7b7b7;
$color-label: #979797;
$color-base: #000000;
$color-sale: #fcd918;

$color-background-error: #fbe0e0;
$color-text-error: #790404;
$color-background-success: $color-background-tinted;
$color-text-success: $color-copy;
$color-background-notice: $color-background-tinted;
$color-text-notice: $color-copy;

@font-face {
  font-family: 'Graphik Regular';
      src: url('../fonts/Graphik-400-Regular.woff') format('woff');
      //font-weight: normal;
}

// @font-face {
//   font-family: 'SF Pro Semibold';
//       src: url('../fonts/SF-Pro-Display-Semibold.woff') format('woff');
//       font-weight: 500;
// }

@font-face {
  font-family: 'Tiempos Headline Medium';
      src: url('../fonts/TiemposHeadline-Medium6.woff') format('woff');
      font-weight: 500;
}

$font-headings: "Tiempos Headline Medium", sans-serif;
$font-body: "Graphik Regular", sans-serif;

  // Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

.hasScribblyLine {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0.25rem;
    top: 80%;
    background-image: url("../images/line.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: block;
    opacity: 0.25;
  }
}
