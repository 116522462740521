.footer {
  margin-top: 3rem;

  .top {
    background-color: $color-background-tinted;
    padding-top: 4rem;
    padding-bottom: 4rem;

    & > * {
      grid-template-columns: repeat(3, auto);
      display: grid;
      gap: 1rem;

      .content {
        max-width: 18rem;
      }

      h3 {
        font-size: 1.5rem;
        margin-bottom: -0.5rem;
      }
    }
  }
  .center {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: calc(100% - 3rem);
      height: 1px;
      background-color: $color-border;
      position: absolute;
      left: 1.5rem;
      top: 100%;
    }

    nav {
      display: flex;
      flex-direction: column;

      h4 {
        margin: 0 0 1rem 0;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      a {
        padding: 0.5rem 0;
        display: inline-flex;
        color: $color-copy;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;

    .copy {
      font-weight: 500;
      margin-right: 1rem;
      font-size: 0.8rem;
    }

    nav {
      margin-right: 1rem;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
      }
      a {
        color: $color-copy;
        padding: 2.5rem 1.25rem;
        display: inline-flex;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .webba {
      margin-left: auto;
      color: $color-copy;
      padding: 2.5rem 0 2.5rem 1.25rem;

      strong {
        color: #00bff0;
        font-weight: normal;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.newsletter {
  min-width: 24rem;

  form {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.5rem;
    width: 100%;
  }

  .gform_fields {
    list-style: none;
    margin: 0;
    padding: 0;

    label {
      display: none;
    }
  }
  .gform_footer input[type="submit"] {
    @extend .btn;

    background-color: $color-primary;
    padding: 0.75rem 1.5rem;
    border-radius: 0.125rem;
    transition: background-color 0.2s ease-out;
    color: $color-white;
    text-transform: uppercase;
    font-size: 0.8125rem;
    letter-spacing: 0.0625em;
    font-weight: 500;
    height: calc(100% - 1px);

    &:hover {
      background-color: darken($color-primary, 10%);
    }
  }

  .gform_confirmation_wrapper {
    line-height: 2rem;
    font-size: 1rem;
    color: $color-white;
    background: $color-primary;
    padding: 0.5rem 1rem;
    border-radius: 0.125rem;
  }
}

.socials {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  & > * + * {
    margin-left: 0.375rem;
  }

  a {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    background: $color-black;
    color: #fff;
    border-radius: 99rem;
    -webkit-appearance: none;
    transition: 0.2s ease;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      opacity: 0.8;
    }

    &.facebook {
      background-image: url("../images/facebook.svg");
    }

    &.instagram {
      background-image: url("../images/instagram.svg");
    }

    &.pinterest {
      background-image: url("../images/pinterest.svg");
    }
  }
}

@media screen and (max-width: 64rem) {
  .footer {
    .center {
      &:after {
        width: calc(100% - 1.5rem);
        left: 0.75rem;
      }
    }
    .top > *,
    .center {
      grid-template-columns: 1fr;
      row-gap: 1.5rem;
    }

    nav {
      order: 2;
    }

    // .center nav ul {
    //   height: 0;
    //   overflow: hidden;
    // }

    .newsletter {
      order: 1;
    }

    .bottom {
      flex-direction: column-reverse;
      padding-top: 2rem;
      padding-bottom: 2rem;

      & > * {
        width: 100%;
      }

      nav {
        margin-right: 0;
        ul {
          flex-direction: column;
        }

        a {
          padding: 0.5rem 0;
        }
      }

      .webba {
        padding: 0.5rem 0;
        margin-top: 1rem;
      }
      .copy {
        margin-right: 0;
      }
    }
  }

  .newsletter {
    min-width: unset;
  }
}
