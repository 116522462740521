.checklist {
  display: flex;
  flex-direction: column;
  font-size: 0.9375rem;

  li {
    display: flex;
    align-items: center;
    color: $color-copy;

    &:before {
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("./../images/icon-circle-check.svg");
      margin-right: 0.5rem;
    }
  }

  &.--horizontal {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    padding: 2rem 0;

    @media screen and (max-width: 56rem) {
      flex-direction: column;
      margin: 0;
    }

    li {
      color: $color-text;
      &:before {
        background-image: url("./../images/icon-check.svg");
      }
    }
  }
}
