.orders {
  li {
    width: 100%;
    margin-bottom: 12px;

    a {
      display: flex;
      align-items: center;
      padding: 24px 28px;
      border: 1px solid $color-border;
      background: #fff;
      box-shadow: 0px 3px 20px rgba(141, 141, 141, 0);
      transition: 0.15s ease;
      color: $color-text;
      text-decoration: none;

      &:hover {
        box-shadow: 0px 3px 20px rgba(141, 141, 141, 0.16);
        // border-color: darken($color-border, 20%);
        text-decoration: none;
      }

      > div {
        flex-shrink: 0;
        padding: 0 12px;
      }
    }

    &:hover {
      position: relative;
      z-index: 99;
    }
  }

  .order__date-number {
    padding-right: 40px;
  }

  .order__date {
    font-size: 1em;
    margin: 0;
  }

  .order__number {
    font-size: 0.875em;
  }

  .order__products {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-x: auto;

    img {
      width: 48px;
      height: auto;
      margin-right: 16px;
      min-width: 48px;
      white-space: nowrap;
    }
  }

  .order__status {
    padding-right: 28px;
  }
}

.order__status {
  span {
    background: $color-primary;
    color: #fff;
    display: block;
    padding: 5px 12px;
    font-size: 0.875em;
  }

  &.on-hold span {
    background: $color-primary;
  }

  &.cancelled span {
    background: #ff563d;
  }
}

.single-order {
  position: relative;

  .order__status {
    position: absolute;
    right: 0px;
    top: -55px;
    display: none;
  }
}

.single-order__block {
  padding: 24px;
  border: 1px solid $color-border;
  margin-bottom: 32px;
  font-size: 0.875em;

  h2 {
    font-size: 1rem;
  }
}

.single-order__notes {
  padding-bottom: 8px;
}

.single-order__content {
  margin-bottom: 24px;

  a {
    font-family: $font-body;
    font-weight: 300;
    font-size: 1rem;
  }
}

.single-order__date {
  color: $color-label;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
}

.order-item {
  display: flex;
  color: $color-text;
  text-decoration: none;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid $color-border;

  .order-item__image {
    width: 56px;
    height: 56px;
    margin-right: 12px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  h4 {
    font-weight: 400;
    font-size: 0.875rem;
  }

  .order-item__price {
    align-self: flex-start;
  }

  &:hover {
    color: $color-text;
  }
}

.single-order__shipping .shipping-provider img {
  float: right;
  height: 16px;
  margin-left: 12px;
}

.single-order__shipping--tnt .shipping-provider img {
  height: 22px;
}

.totals-line--order_total span {
  font-weight: 600 !important;
}

.totals-line--shipping-blanco {
  span:last-child {
    text-align: right;
    padding-right: 16px;
  }
}

@media screen and (max-width: 767px) {
  .orders {
    li {
      a {
        padding: 16px 0;
        border: 0;
        border-bottom: 1px solid $color-border;
        flex-wrap: wrap;

        &:hover {
          box-shadow: none;
        }
      }

      &:first-child a {
        border-top: 1px solid $color-border;
      }
    }

    .order__date-number {
      order: 10;
      width: 40%;
      padding: 0 8px 0 0;
    }

    .order__products {
      order: 40;
      width: 100%;
      padding: 16px 0 0 0;
    }

    .order__status {
      order: 20;
      width: 40%;
      padding: 0 8px;
      display: flex;
      justify-content: flex-end;
    }

    .order__price {
      order: 30;
      width: 20%;
      padding: 0 0 0 8px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .single-order__block {
    border: 0;
    border-bottom: 1px solid $color-border;
    padding: 0 0 24px;
  }
}

.totals-line {
  margin: 10px 0;

  > span {
    &:first-child {
      font-weight: 600;
      font-family: $font-headings;
    }

    &:nth-child(2) {
      font-weight: 300;
      font-family: $font-body;
      font-size: 1rem;
    }
  }
}