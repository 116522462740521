[data-tooltip] {
  -webkit-appearance: none;
  font: inherit;
  position: relative;
  z-index: 999999;

  .tooltip {
    position: absolute;
    width: 16rem;
    background: $color-background-alt;
    box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.09);
    bottom: calc(100% + 1rem);
    padding: 1rem;
    color: $color-text;
    font-weight: 300;
    line-height: 1.4;
    z-index: 9999;
    left: calc(50% - 8rem);
    border-radius: 0.25rem;
    opacity: 0;
    transform-origin: center bottom;
    transform: scale(0.75);
    transition: 0.2s ease-in;

    &.active {
      opacity: 1;
      transform: scale(1);
      transition: 0.2s ease-out;
    }

    &:after {
      content: "";
      width: 1rem;
      height: 1rem;
      position: absolute;
      display: block;
      background: $color-background-alt;
      top: calc(100% - 0.5rem);
      left: calc(50% - 0.5rem);
      transform: rotate(45deg);
      border-radius: 0.125rem;
    }
  }
}

.checkout__totals.--terms [data-tooltip] {
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.75rem;
  color: lighten($color-background-alt, 3%);
  font-weight: 800;
  background-color: darken($color-background-alt, 10%);
  border-radius: 99rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;

  &:focus {
    outline: 0;
  }
}
