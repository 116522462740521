.singleProduct {
  width: 100%;

  .productHead, .productInfo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;

    @media (max-width: 64rem) {
      grid-template-columns: auto;
    }
  }

  > * {
    margin: 1.5rem 0;
  }

  .gallery {
    width: 100%;
    flex-grow: 1;
    position: relative;
    z-index: 0;

    > span {
      position: absolute;
      color: transparent !important;
      background: url('../images/pinterest_red.svg');
      background-size: 1.5rem;
      background-repeat: no-repeat;
      box-shadow: none;
      z-index: 1;
      width: 1.6rem;
      height: 1.6rem;
      top: 75px;
      right: 25px;
      border-radius: 39px;

      &:hover {
        background-color: transparent;
        color: transparent !important;
        opacity: 0.5;
      }
    }

    .btn.--favorite {
      position: absolute;
      right: 1rem;
      top: 1rem;
      transition: background-color 0.2s ease-out;
      will-change: background-color;
      z-index: 2;
      width: 3rem;
      height: 3rem;

      img {
        width: 2rem;
        max-width: initial;
        max-height: initial;
      }

      &:hover {
        background-color: $color-background-tinted;
      }

      &[data-favorite="true"] {
        background-color: $color-primary;

        img {
          filter: invert(1);
        }

        &:hover {
          background-color: darken($color-background-tinted, 10%);
          
          img {
            filter: unset;
          }
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }

  .btn.--zoom {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    transition: background-color 0.2s ease-out;
    will-change: background-color;
    z-index: 2;
    width: 3rem;
    height: 3rem;
    border-radius: 999rem;

    img {
      width: 1rem;
      max-width: initial;
      max-height: initial;
    }

    &:hover {
      background-color: $color-primary;
    }

    &:focus {
      outline: 0;
    }
  }

  .info {
    min-width: 20ch;
    flex-shrink: 0;

    h1 {
      margin: -0.375rem 0 0.5rem 0;
      font-size: calc(1rem + 1vw);
      letter-spacing: 0.08rem;
    }

    .infoPrice {
      display: flex;
      align-items: center;
    }

    .price,
    .price > ins {
      font-weight: 500;
      font-size: calc(1rem + 1vw);
      letter-spacing: 0.03rem;
    }

    .price > del {
      font-weight: 400;
      margin-top: 0.125rem;
    }

    .tag {
      margin-left: 1rem;
      border-radius: 999rem;
      color: $color-black;
      background: #fcd91d;
      padding: 0.25rem 0.75rem;
      font-size: 0.8125rem;
    }

    .stock {
      margin: 0.25rem 0;
      line-height: initial;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.in-stock:before {
        content: "";
        width: 1.25rem;
        height: 1.25rem;
        display: inline-flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../images/icon-check.svg");
        margin-right: 0.5rem;
      }
    }

    // .checklist {
    //   padding-top: 2rem;
    //   padding-left: 0;
    //   margin-top: 0;
    //   margin-bottom: 2rem;

    //   li {
    //     margin: 0 0 0.5rem 0;
    //   }
    // }

    .extra_product_info {
      padding-top: 2rem;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 2rem;

      .truck {
        margin-left: 5px;
        margin-right: 10px;
      }

      > ul {
        list-style: none;
        margin-top: 2px;
        margin-left: -15px;

        > li {
              position: relative;
              display: flex;
              align-items: center;
              line-height: 1.8;

            > a {
                  font-size: 1rem;
              }

              &:before {
                  position: relative;
                  top: 0;
                  content: "";
                  margin-right: -2px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-position: center;
                  background-size: 1.8em;
                  background-repeat: no-repeat;
                  background-image: url("./../images/icon-circle-check.svg");
                  width: 1.4em;
                  height: 1.4em;
                  left: -20px;
              }
            }
        }
    }

    .cart {
      margin: 2rem 0 0 0;
      display: flex;

      .quantity {
        margin-right: 0.375rem;
        max-width: 5rem;
      }

      button[type="submit"] {
        //@extend .btn;
        background-color: $color-primary;
        padding: 0.75rem 1.5rem;
        border-radius: 0.125rem;
        transition: background-color 0.2s ease-out;
        min-height: 3rem;
        color: $color-white;
        text-transform: uppercase;
        font-size: 0.8125rem;
        letter-spacing: 0.0625em;
        font-weight: 500;
        border: none;

        &:hover {
          background-color: darken($color-primary, 10%);
        }
      }
    }
  }

  .description,
  .specs {
    h2:first-child {
      margin: 2rem 0 1rem 0;
      font-size: 1.125rem;
      width: 100%;
      padding: 0.75rem 0.75rem;
      background-color: $color-background-tinted;
    }
    p {
      padding: 0 0.75rem;
      line-height: 1.8;
    }
  }
}

.woocommerce-product-attributes {
  tr,
  th,
  td {
    text-align: left;
    background-color: #fff !important;
    font-weight: 400;
  }
  td,
  th {
    padding: 0.5rem 0.75rem;
  }
  tr {
    border-bottom: 1px solid $color-border;
  }
}

@media screen and (max-width: 64rem) {
  .singleProduct {
    display: block;
  }
}

.product__card {
  li {
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    padding: 1rem;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23dad6d6' stroke-width='2' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      opacity: 0;
      transition: opacity 0.15s;
      pointer-events: none;
      z-index: -1;
    }

    &:hover:after {
      opacity: 1;
    }

    .tag.--product {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      padding: 0.25rem 0.75rem;
      z-index: 2;
      background: #fcd91d;
      border-radius: 99rem;
      font-size: 0.8125rem;
      color: $color-black;
    }

    .btn.--favorite {
      position: absolute;
      left: 0.75rem;
      top: 0.75rem;
      padding: 0;
      transition: background-color 0.2s ease-out;
      will-change: background-color;
      z-index: 2;

      img {
        width: 2rem;
        height: 2rem;
        max-width: initial;
        max-height: initial;
        background-color: $color-background-tinted;
      }

      &:hover {
        background-color: $color-background-tinted;
      }

      &[data-favorite="true"] {
        background-color: $color-primary;
        
        img {
          filter: invert(1);
        }

        &:hover {
          background-color: darken($color-background-tinted, 10%);
          
          img {
            filter: unset;
          }
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }

  .image {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    padding-bottom: 130%;
    position: relative;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      top: 0;

      @supports (object-fit: cover) {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .category {
    text-decoration: underline;
    color: $color-placeholder;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.9375rem;
  }

  .name {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.0625em;
  }
}

.price {
  display: flex;
  align-items: flex-start;
  font-family: $font-headings;
  font-weight: 400;
  font-size: 1.25rem;
  margin-top: auto;

  & > ins {
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    font-size: 1.25rem;
    font-weight: 400;
  }

  & > del {
    color: $color-label;
    font-size: 0.8125rem;
    margin-left: 0.75rem;
    margin-top: 0;
    font-family: $font-body;
  }
}

.priceNote {
  color: $color-copy;
  margin: 0.25rem 0;
}

.variations_form {
  display: flex;
  flex-direction: column;

  .variations {
    margin-bottom: 10px;

    .variations-wrapper {

      label {
        margin: 2rem 0 1rem 0;
        font-size: 1.125rem;
        width: 100%;
        padding: 0.75rem 0.75rem;
        background-color: #f5f5f5;
        font-family: "Tiempos Headline Medium", sans-serif;
        font-weight: 600;
        margin-bottom: 3px;
      }

      .value {
        display: flex;
        align-items: center;
      }
    }    
  }

  .reset_variations {
    display: flex;
    align-items: center;
    color: transparent;
    margin-left: 10px;
    
    &.show_icon {
      position: relative;

      &:before {
        position: relative;
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-image: url("../images/cross.svg");
        background-size: 15px;
        background-repeat: no-repeat;
      }
    }
  }
}

.single_variation_wrap {
  display: flex;
}

.woocommerce-variation {
  margin-left: 5px;
  margin-right: 25px;

  .price {
    &:last-child {
      position: relative;
      top: 3px;
    }
  }
}

.woocommerce-variation-add-to-cart {
  display: flex;
}

.woocommerce-product-attributes {
  position: relative;
  top: -8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0.75rem;

  .woocommerce-product-attributes-item {
    display: flex;

    label {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 0;
      line-height: 2.8;
    }

    span {
      display: flex;
      align-items: center;
      width: 50%;
      line-height: 2.8;

      p {
        margin-bottom: 0;
      }
    }
  }
}