.optional {
  display: none;
}

.form-row {
  display: flex;
  flex-direction: column;
  line-height: 1;
  margin: 0 0 1rem 0;
  width: 100%;

  label {
    line-height: 1;
    font-weight: 500;
    color: $color-text;
    margin-bottom: 0.375rem;
  }
}

.checkout__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6rem;
  margin-top: 2.5rem;

  @media screen and (max-width: 28rem) {
    flex-direction: column-reverse;

    a {
      margin-top: 1rem;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration-style: dashed;
    }
  }
}

.form-row .required {
  text-decoration: none;
}

.checkout__totals .line {
  width: 100%;
}

@media screen and (min-width: 64rem) {
  .woocommerce-billing-fields__field-wrapper {
    margin: 3rem -0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: $color-border;
    padding: 40px;

    .form-row {
      label {
        font-size: 1rem;
        font-weight: 600;
        padding-left: 5px;
        margin-top: 10px;
      }
    }

    > * {
      padding: 0 0.5rem;
    }
  }

  #billing_company_field {
    margin-right: 50%;
  }
  #billing_company_field,
  #billing_first_name_field,
  #billing_last_name_field {
    width: 50%;
  }
  #billing_address_1_field {
    width: 80%;
  }
  #billing_address_2_field {
    width: 20%;
    justify-content: end;
  }

  #billing_postcode_field {
    width: 35%;
  }
  #billing_city_field {
    width: 65%;
  }
}

.checkout {
  display: grid;
  grid-template-columns: 1fr 20rem;
  gap: 0 2rem;

  .woocommerce-NoticeGroup {
    grid-column: span 2;
  }

  @media screen and (max-width: 56rem) {
    display: flex;
    flex-direction: column;

    .checkout__data {
      order: 1;
      margin-top: 2rem;
    }
  }
}

.checkout-icon {
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 1px solid $color-border-light;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (max-width: 40rem) {
    padding-left: 1rem;
    margin-left: 1rem;
  }
}

.checkout__totals {
  margin-bottom: 0;
  padding: 1.5rem 1.25rem;
  font-size: 0.875rem;

  .line {
    padding: 0.5rem 0;
  }

  &.--terms {
    padding: 1rem;
    background: lighten($color-background-alt, 3%);
    margin: 0 0 1rem 0;
  }
}

.line.--in-3 {
  border-bottom: 0;
}

.checkout__totals + a {
  width: 100%;
  text-align: right;
  text-decoration: underline;
  display: block;

  &:hover {
    text-decoration-style: dashed;
  }
}

.payment {
  .methods {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    gap: 0.25rem;

    li {
      padding: 0.75rem;
      display: flex;
      border: 1px solid $color-border-light;
      flex-direction: column;
      transition: 0.15s ease;

      &:hover {
        border-color: $color-border;
      }

      & > input {
        display: none;
      }

      & > label {
        font-weight: 500;
        line-height: 1;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &:before {
          content: "";
          display: inline-flex;
          width: 1.5rem;
          height: 1.5rem;
          background: #fff;
          transition: 0.2s ease;
          background-size: 1rem;
          background-position: center 60%;
          background-repeat: no-repeat;
          border: 1px solid $color-border;
          border-radius: 999px;
          margin-right: 0.5rem;
          order: 2;
        }

        img {
          margin-right: 0.5rem;
          max-width: 24px;
        }
      }

      & > input:checked ~ label {
        &:before {
          background-image: url("../images/icon-check-white.svg");
          background-color: $color-primary;
          border-color: $color-primary;
        }
      }
    }
  }
}

.payment_method_mollie_wc_gateway_ideal {
  p:last-child {
    margin: 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      right: 0.5rem;
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0;
    }
  }

  select {
    width: 100%;
    height: 2.5rem;
    border-radius: 0;
    -webkit-appearance: none;
    font: inherit;
    font-size: 0.875rem;
    padding: 0 0.5rem;
    border-color: $color-border;
    background: #ffffff;
  }
}
