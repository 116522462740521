
.home-banner {
  a {
    text-decoration: none;
    box-shadow: none;
    outline: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.home-banner--type_1 {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .home-banner__image {
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 512px;
    }
  }

  .home-banner__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $color-primary;
    padding: 40px;

    @include media-breakpoint-up(lg) {
      width: 50%;
      padding: 40px;
    }

    h2, p {
      font-family: $font-headings;
      font-size: 1.5rem;
      color: $color-text-white;
      text-align: center;
      line-height: 1.4;
      text-transform: uppercase;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
      margin-top: 20px;
      text-transform: unset;
    }

    .home-banner__button {
      position: relative;
      text-transform: uppercase;
      font-size: 0.8125rem;
      color: $color-text-white;
      letter-spacing: 0.0625em;
      font-weight: $font-weight-bold;
      margin-top: 1rem;
      border: 0;
      background-color: transparent;
      -webkit-transition: all .15s ease-in-out;
      -moz-transition: all .15s ease-in-out;
      transition: all .15s ease-in-out;

      &:after {
        position: absolute;
        top: 120%;
        left: -5px;
        content: "";
        width: 120%;
        height: .25rem;
        background-image: url('../images/line.svg');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: block;
        opacity: .55;
        filter: invert(1);
        -webkit-transition: all .15s ease-in-out;
        -moz-transition: all .15s ease-in-out;
        transition: all .15s ease-in-out;
      }

      &:hover {
        text-decoration: none;
        color: darken($color-text-white, 15%);

        &:after {
          filter: invert(0.9);
        }
      }
    }
  }
}


.home-banner--type_2 {
  display: flex;
  position: relative;
  height: 512px;

  .home-banner__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    @include media-breakpoint-up(lg) {
      height: 100%;
    }
  }

  .home-banner__content {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 65px;
    background-color: transparent;
    padding: 15px 15px 0 15px;
    overflow: hidden;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    @include media-breakpoint-up(lg) {
      width: 100%;
      align-items: flex-start;
    }

    h2 {
      font-family: $font-headings;
      font-size: 1.7rem;
      color: $color-white;
      text-align: center;
      line-height: 1.4;
      margin-bottom: 0;
      text-transform: uppercase;
      text-shadow: 1px 1px 5px rgba($color-black, 0.4);
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;

      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }
    }

    p {
      height: 0;
      opacity: 0;
      font-family: $font-headings;
      color: $color-white;
      margin-top: 5px;
      position: relative;
      margin-bottom: 0;
      padding-right: 100px;
      line-height: 1.8;
      text-shadow: 1px 1px 5px rgba($color-black, 0.4);
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;

      &:hover {
        &:after {
          right: -5px;
        }
      }
    }

    .home-banner__button {
      position: relative;
      text-transform: uppercase;
      font-size: 0.8125rem;
      color: $color-text-white;
      letter-spacing: 0.0625em;
      font-weight: $font-weight-bold;
      margin-top: 1rem;
      width: 100%;
      border: 0;
      background-color: transparent;
      -webkit-transition: all .15s ease-in-out;
      -moz-transition: all .15s ease-in-out;
      transition: all .15s ease-in-out;
    }
  }

  &:hover {
    .home-banner__image {
      height: 100%;
    }
    .home-banner__content {
        height: 135px;
        background-color: rgba($color-white, 0.3);

        h2 {
          color: $color-white;
          //text-shadow: 3px 3px 6px $color-black;
        }

        p {
            opacity: 1;
            height: 65px;
        }
      }
  }
}


.homeBanners {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 2rem;
  align-items: stretch;
  padding-top: 0rem;
  padding-bottom: 2rem;
}

.homeBanner {
  display: flex;
  position: relative;
  min-height: 32rem;
}

.homeBanner--type_1 {
  .homeBanner__content {
    padding: 1.5rem;
    width: 50%;
    background: $color-primary;
    color: $color-text-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
      color: $color-text-white;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 0;
    }

    .btn {
      text-transform: uppercase;
      font-size: 0.8125rem;
      letter-spacing: 0.0625em;
      font-weight: 500;
      margin-top: 1rem;
      @extend .hasScribblyLine;

      &:after {
        filter: invert(1);
        width: 5rem;
        left: calc(50% - 2.5rem);
        top: 110%;
        opacity: 0.5;
      }
    }
  }
  picture {
    width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.homeBanner--type_2 {
  picture {
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .homeBanner__content {
    position: absolute;
    width: 80%;
    background: #ffffff;
    left: 0;
    bottom: 0;
    padding: 2rem 4rem 1rem 2rem;

    h3 {
      font-size: 2rem;
    }

    span {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      transform: translateX(0rem);
      transition: 0.2s ease;
    }

    &:hover {
      span {
        transform: translateX(0.25rem);
      }
    }
  }
}

.homeCategories {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  gap: 2rem;

  @media screen and (max-width: 56rem) {
    padding-top: 0rem;
  }
}

.homeCategory {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    h3 {
      color: $color-primary;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
    }
  }

  .tag {
    position: absolute;
    width: 7rem;
    height: 7rem;
    z-index: 2;
    top: 1rem;
    right: 1rem;
    display: flex;
    text-transform: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: $color-white;
    color: $color-text;
    border-radius: 999rem;

    span:first-child {
      font-family: $font-headings;
      font-weight: 600;
      font-size: 1.5rem;
    }
    span:last-child {
      font-size: 0.875rem;
    }

    &.--percent {
      background: #fcd91d;
    }
  }

  .homeCategory__content {
    background: $color-background-tinted;
    text-align: center;
    padding: 1.25rem;
    flex-grow: 1;

    h3 {
      font-size: 1.25rem;
      margin: 0;
      font-weight: 300;
    }
  }

  picture {
    overflow: hidden;
    width: 100%;
    position: relative;
    padding-bottom: 75%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1);
      transition: 0.2s ease-in-out;
    }
  }

  &:hover picture img {
    transform: scale(1.01);
  }
}

.homeUsps {
  background: $color-background-tinted;
}

.homeAbout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
  gap: 4rem;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;

  h2 {
    font-size: 2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.homeProducts {
  padding-top: 3rem;
  padding-bottom: 3rem;

  h3 {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.homeInstagram {
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media screen and (max-width: 32rem) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
