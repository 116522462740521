.categoryList {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 56rem) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  @media screen and (max-width: 32rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  li {
    flex-grow: 0;
    flex-shrink: 0;

    a {
      background: $color-background-tinted;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1.5rem;
      min-height: 10rem;
      position: relative;

      img {
        margin-bottom: 1rem;
      }

      span {
        position: absolute;
        bottom: 1.5rem;
        left: 0;
        width: 100%;
        text-align: center;
        line-height: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0;
  padding: 0;
  list-style: none;

  &.swiper-container {
    display: flex;

    .swiper-button-next,
    .swiper-button-prev {
      @extend .btn;
      background-image: url("../images/icon-arrow-right.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1rem;
      background-color: $color-background-tinted;
      border-radius: 999rem;
      width: 3rem;
      height: 3rem;
      margin-top: -1.5rem;
    }

    .swiper-button-prev {
      transform: rotate(180deg);
    }

    @media screen and (min-width: 84rem) {
      overflow: visible;

      .swiper-slide {
        opacity: 0;
        transition: 0.15s ease;
      }

      .swiper-slide-active,
      .swiper-slide-active + *,
      .swiper-slide-active + * + *,
      .swiper-slide-active + * + * + * {
        opacity: 1;
      }

      .swiper-button-next {
        right: -3.5rem;
      }
      .swiper-button-prev {
        left: -3.5rem;
      }
    }

    @media screen and (max-width: 32rem) {
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }
  }

  @media screen and (max-width: 64rem) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 56rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  .no-products {
    grid-column: span 4;
  }

  li {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 1rem;
    z-index: 0;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23dad6d6' stroke-width='2' stroke-dasharray='8%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      opacity: 0;
      transition: opacity 0.15s;
      pointer-events: none;
      z-index: -1;
    }

    &:hover:after {
      opacity: 1;
    }

    .tag.--product {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      padding: 0.25rem 0.75rem;
      z-index: 2;
      background: #fcd91d;
      border-radius: 99rem;
      font-size: 0.8125rem;
      color: $color-black;
    }

    .btn.--favorite {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      padding: 0;
      transition: background-color 0.2s ease-out;
      will-change: background-color;
      background-color: transparent;
      z-index: 2;

      img {
        width: 2rem;
        height: 2rem;
        max-width: initial;
        max-height: initial;
      }

      &:hover {
        background-color: $color-background-tinted;
      }

      &[data-favorite="true"] {
          background-color: $color-primary;
          
          img {
            filter: invert(1);
          }
  
          &:hover {
            background-color: darken($color-background-tinted, 10%);
            
            img {
              filter: unset;
            }
          }
      }

      &:focus {
        outline: 0;
      }
    }
  }

  .image {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    padding-bottom: 130%;
    position: relative;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      top: 0;

      @supports (object-fit: cover) {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .category {
    text-decoration: underline;
    color: $color-placeholder;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.9375rem;
  }

  .name {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.0625em;
  }
}

.price {
  display: flex;
  align-items: flex-start;
  font-family: $font-headings;
  font-weight: 400;
  font-size: 1.25rem;

  & > ins {
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    font-size: 1.25rem;
    font-weight: 400;
  }

  & > del {
    color: $color-label;
    font-size: 0.8125rem;
    margin-left: 0.75rem;
    margin-top: 0;
    font-family: $font-body;
  }
}

.priceNote {
  color: $color-copy;
  margin: 0.25rem 0;
}

.catalog {
  @media screen and (min-width: 64rem) {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: 18rem auto;
    grid-column-gap: 2rem;

    .catalog__aside {
      grid-column: 1 / span 1;
      grid-row: 1 / span 2;
    }
    .catalog__items {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
    }
    .catalog__header {
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }
  }
}

.catalog__header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
  gap: 1rem;

  h1 {
    margin: 0;
    font-size: 2rem;
  }

  @media screen and (min-width: 56rem) {
    .woocommerce-ordering {
      position: absolute;
      right: 0;
      width: 38%;
    }
  }
  .woocommerce-result-count {
    margin: 0;
  }
}

.catalog__items .products,
.search-results .products {
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 48rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 32rem) {
    grid-template-columns: 1fr;
  }
}

.related.products,
.deals.products {
  display: block;
  grid-column: span 2;

  h2 {
    margin: 3rem 0 1.5rem 0;
    font-size: 2rem;
    width: 100%;
    text-align: center;
  }
}

.deals.products h2 {
  text-align: left;
  margin-top: 3rem;
}

.products.--favorites {
  grid-template-columns: repeat(3, 1fr);
}

.deals.products {
  .products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  li {
    padding: 0;
    width: 100%;

    &:after {
      display: none;
    }

    .images {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      gap: 1rem;
      margin: 0 0 1rem;
    }

    .image {
      width: 100%;
      position: relative;
      margin: 0;
      padding-bottom: 85%;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E0E0E0' stroke-width='2' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        opacity: 1;
        pointer-events: none;
        z-index: -1;
      }

      img {
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        left: 0.5rem;
        top: 0.5rem;
        object-fit: contain;
      }
    }

    .tag {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      padding: 0.25rem 0.75rem;
      z-index: 2;
      background: #fcd91d;
      border-radius: 99rem;
      font-size: 0.8125rem;
      color: $color-black;
      // color: $color-white;
    }

    .price_and_add_to_cart {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @include media-breakpoint-down(md) {
    .products {
      grid-template-columns: auto;
    }
  }
}

.term-description {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  margin-bottom: 4rem;
  border-top: 1px solid $color-border-light;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    font-family: $font-body;
    text-transform: initial;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  }

  p,
  ul,
  ol {
    margin: 0.5rem 0 1rem 0;
  }
}

.woocommerce-ordering {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0;

  span {
    &:first-child {
      width: 25%;
      margin-right: 10px;
      display: none;

      @include media-breakpoint-up(xl) {
        display: inline-block;
      }
    }

    &:last-child {
      width: 75%;
    }
  }
}

.btn.--filter {
  display: none;
}

.filter--close {
  display: none;
}

@include media-breakpoint-down(md) {
  .btn.--filter {
    display: flex;
    background: $color-black;
    color: #fff;
  }
  .catalog__aside {
    position: fixed;
    background: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 99999;
    transition-delay: 0.2s;
    transition: 0.2s ease;
    pointer-events: none;

    .product-filter {
      display: none;
      width: 100%;
      height: 100vh;
      position: relative;
      top: 0;
      background: #fff;
      transform: translateY(100vh);
      transition: 0.2s ease-in;
    }
  }

  .filter-active .catalog__aside {
    background: rgba(0, 0, 0, 0.3);
    transition-delay: 0s;
    pointer-events: initial;

    .product-filter {
      position: block;
      display: block;
      transform: translateY(0);
      transition: 0.2s ease-out;
      transition-delay: 0.2s;
    }
  }

  .filter--close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background-color: #000000;
    border-radius: 0.25rem;
    padding: 0.25rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .product-filter {
    &__container {
      padding: 6rem 1.5rem 2rem;
      overflow-y: scroll;
      overflow-x: unset;
      height: calc(100% - 64px);
    }
  }
}

.product-filter__footer {
  display: none;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 0.5rem 1.5rem;
  z-index: 2;

  @include media-breakpoint-down(md){
    display: flex;
    bottom: 0;
    max-height: 100px;
    position: sticky;
  }

  .btn {
    background: $color-black;
    color: #fff;
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 0;
    border: 0 !important;
  }
}

.navigation-arrow {
  position: relative;
  display: flex;
  width: 75%;

  .orderby,
  .select-css {
      display: block;
      width: 100%;
      padding: 14px 12px 14px 12px;
      font-size: 1rem;
      line-height: 1.42857;
      background-color: transparent;
      background-image: none;
      border: 1px solid #cecece;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;

      &:focus {
          outline: none;
      }
  }

  .option-select {
    font-size: 1rem;
    color: $color-black;
  }
  
  &:after {
      content: '';
      position: absolute;
      top: 20px;
      right: 12px;
      cursor: pointer;
      pointer-events: none;
      background-image: url('../images/caret-down.svg');
      background-size: cover;
      color: $color-black;
      width: 12px;
      height: 12px;
  }
}

.variations {
  .navigation-arrow {
    width: 100%;
  }
}

.berocket_aapf_widget-wrapper {
  ul {
    .flex-wrapper {
      width: 100%;
      margin: 0 auto;
    }
  }

  .berocket_color_span_block,
  .berocket_color_multiple_4 {
    overflow: hidden;
  }
}