.nav--primary {
  position: relative;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: $color-background;
  width: 100%;
  height: 101vh;
  z-index: 5;

  &.menu-active {
    position: fixed;
    display: flex;

    .menu {
      position: absolute;
      top: 0;
      height: 100vh;
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    top: unset;
    left: unset;
    padding: 0 25px;
    margin-bottom: 45px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      row-gap: 8px;
      justify-content: start;
      flex-wrap: wrap;
      border-top: 1px solid #cecece;
      border-bottom: 1px solid #cecece;
      padding: 0;
    }

    @include media-breakpoint-up(xl) {
      justify-content: center;
    }

    > li {
      position: relative;
      width: 100%;
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: unset;
        width: unset;
        margin: 0 12px;

        &:after {
          display: block;
          position: absolute;
          content: "";
          background-color: $color-primary;
          height: 3px;
          width: 0;
          bottom: 0;
          opacity: 0;
          -webkit-transition: 0.2s all ease-in-out;
          -moz-transition: 0.2s all ease-in-out;
          -o-transition: 0.2s all ease-in-out;
          transition: 0.2s all ease-in-out;
        }

        &:hover {
          &:after {
            width: 100%;
            opacity: 1;
          }

          &:last-child {
            &:after {
              opacity: 1;
              background-image: url("../images/green-line.svg");
            }
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &.menu-item-3953 {
          background-color: $color-sale;
          line-height: 1.2;
          border-radius: 25px;

          &:hover {
            &:after {
              display: none;
            }
          }

          a {
            line-height: 1.2;
            padding: 10px;
            border-radius: 25px;
            padding: 10px 18px;

            &:hover {
              color: $white;
            }
          }
        }

        &:last-child {
          @include media-breakpoint-up(xl) {
            margin-left: 15px;
            margin-right: 0;
          }

          a {
            @include media-breakpoint-up(lg) {
              line-height: 1.4;
              padding: 5px 0;
            }
          }

          &:after {
            position: absolute;
            top: 102%;
            left: 0;
            content: "";
            width: 100%;
            height: 0.35rem;
            background-color: transparent;
            background-image: url("../images/line.svg");
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 100% auto;
            display: block;
            opacity: 1;
            -webkit-transition: all 0.15s ease-in-out;
            -moz-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;
          }
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-body;
        font-size: 1rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color-base;
        text-decoration: none;
        line-height: 2.5;
        letter-spacing: 0.125rem;
        //pointer-events: none;

        @include media-breakpoint-up(lg) {
          font-size: 0.9rem;
          line-height: 1.4;
          text-align: center;
          padding: 20px 0;
        }

        @include media-breakpoint-up(xl) {
          font-size: 0.9rem;
          //   padding: unset;
          text-align: center;
        }

        &:hover {
          color: $color-primary;
        }
      }

      &.active {
        a {
          color: $color-primary;
        }
      }
    }

    .menu-item-has-children {
      position: relative;

      .sub-menu {
        position: relative;
        display: none;
        flex-direction: column;
        background-color: $white;
        list-style: none;
        align-items: center;
        padding: 20px;

        @include media-breakpoint-up(lg) {
          position: absolute;
          align-items: flex-start;
          top: 60px;
          left: -10px;
          z-index: 6;
          box-shadow: 0 1px 3px $color-label;
        }

        > li {
          display: flex;
          margin: 3px 0;

          a {
            font-family: $font-body;
            font-size: 0.9rem;
            color: $color-base;
            text-decoration: none;
            white-space: nowrap;
            line-height: 1.6;

            @include media-breakpoint-up(lg) {
              padding: 0;
            }

            &:hover {
              color: $color-primary;
            }
          }
        }

        &.active {
          display: flex;
        }
      }
    }
  }

  .nav-text {
    margin-right: 5px;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  // Root nav items

  > .nav-item {
    > .nav-link {
      text-decoration: none;
      color: $color-base;

      // Hover state

      &:hover {
        color: tint($color-base, 80%);
      }
    }
  }
}
