@import "variables";

// Functions
@import "../../node_modules/bootstrap/scss/functions";

// Variables
@import "../../node_modules/bootstrap/scss/variables";

// Bootstrap

@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

// style

@import "checklist";
@import "catalog";
@import "home";
@import "footer";
@import "form";
@import "ajaxfilter";
@import "product";
@import "orders";
@import "wordpress";
@import "breadcrumbs";
@import "cart";
@import "checkout";
@import "account";
@import "tooltip";
@import "searchbar";
@import "pagination";

body {
  font-family: $font-body;
  margin: 0;
  background: $color-background;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: $font-headings;
  font-weight: 600;
  font-size: calc(1.5rem + 1.5vw);
}

h2 {
  font-family: $font-headings;
  font-weight: 600;
  font-size: 2rem;
}

h3 {
  font-family: $font-headings;
  font-weight: 600;
  font-size: 1.5rem;
}

h1,
h2,
h3,
h4 {
  &:first-child {
    margin-top: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  line-height: 2rem;
  font-size: 1rem;
  color: $color-copy;
}

.container {
  max-width: 100%;
  width: 78rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 40rem) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  &.--small {
    width: 56rem;
  }

  &.--page {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }

  // &.main {
  //   padding-top: 4rem;
  //   padding-bottom: 6rem;
  // }
}

.stack {
  & > * {
    margin: 0;

    & + * {
      margin-top: 1rem;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-appearance: none;
  background: none;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 0.5rem 0.75rem;
  font-size: inherit;
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  img {
    max-height: 1.5rem;
    max-width: 1.5rem;
  }

  &.--white {
    background-color: $color-white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.125rem;
    transition: background-color 0.2s ease-out;
    color: $color-text;

    &:hover {
      background-color: darken($color-white, 10%);
    }
  }

  &.--primary {
    background-color: $color-primary;
    padding: 0.75rem 1.5rem;
    border-radius: 0.125rem;
    transition: background-color 0.2s ease-out;
    color: $color-white;
    text-transform: uppercase;
    font-size: 0.8125rem;
    letter-spacing: 0.0625em;
    font-weight: 500;

    &:hover {
      background-color: darken($color-primary, 10%);
    }
  }

  &.--black {
    background-color: $color-black;
    padding: 0.75rem 1.5rem;
    border-radius: 0.125rem;
    transition: background-color 0.2s ease-out;
    color: $color-text-white;

    &:hover {
      background-color: lighten($color-black, 10%);
    }
  }

  &.--blue {
    background-color: $color-background-alt;
    padding: 0.75rem 1.5rem;
    border-radius: 0.125rem;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: darken($color-background-alt, 5%);
    }
  }

  &.--icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;

    img {
      max-width: initial;
      max-height: initial;
      width: 2rem;
      height: 2rem;
    }
  }
}

.btn.--menu {
  width: 2.8rem;
  height: 2.8rem;
  position: absolute;
  top: 2.56rem;
  left: 0.8rem;
  background-color: #f5f5f5;
  border-radius: 99rem;
  margin-right: 1rem;
  display: flex;
  z-index: 99999;

  @include media-breakpoint-up(md) {
    top: 3.1rem;
    left: 1.3rem;
    width: 3.5rem;
    height: 3.5rem;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }

  path:nth-child(2) {
    transform: scaleX(1);
    transition: 0.15s ease;
  }

  &:focus {
    outline: 0;
  }
}

.menu-active .btn.--menu {
  position: fixed;

  path:nth-child(2) {
    transform: scaleX(0.55);
  }
}

.header {
  & > * {
    display: flex;
    align-items: center;
    min-height: 6.75rem;
  }

  .logo {
    position: relative;
    top: -15px;
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: -25px;

    @include media-breakpoint-up(md) {
      top: 5px;
      width: 70%;
      margin-left: -46px;
    }

    @include media-breakpoint-up(lg) {
      width: 50%;
      margin-left: unset;
    }

    img {
      width: 30%;
      height: auto;
    }
  }

  .user--options {
    position: absolute;
    top: 39px;
    right: 12px;
    display: flex;
    justify-content: flex-end;
    width: 24%;

    @include media-breakpoint-up(md) {
      top: 56px;
    }

    @include media-breakpoint-up(lg) {
      position: relative;
      top: unset;
      right: unset;
    }
  }


  &.--checkout {
    border-bottom: 0;
    .logo {
      margin-left: auto;
      width: auto;
    }
  }

  .btn.--favorite,
  .btn.--account,
  .btn.--cart {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;

    img {
      width: 2.5rem;
      height: 2.5rem;
      max-width: initial;
      max-height: initial;
    }
  }

  .btn.--cart {
    position: relative;
    margin-right: 0;

    .count {
      position: absolute;
      display: flex;
      left: 1.2rem;
      top: -2px;
      text-align: center;
      background: $color-background-tinted;
      display: block;
      border-radius: 99rem;
      padding: 0.175rem 0.25rem;
      min-width: 1.5rem;
      font-size: 0.75rem;
      font-weight: 500;

      @include media-breakpoint-up(lg) {
        left: 1.675rem;
        top: 0;
        background: $color-background-tinted;
      }
    }
  }

  @media screen and (max-width: 72rem) {
    & > * {
      display: flex;
      flex-wrap: wrap;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      min-height: initial;
    }
  }
}

#headerAccountPopup {
  position: absolute;
  padding: 2rem;
  background: $color-background;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.07);
  min-width: 22rem;
  top: calc(100% + 3.25rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0;
  transform-origin: center top;
  transform: scale(0.75);
  transition: 0.15s cubic-bezier(0.65, 0, 0.35, 1);
  pointer-events: none;

  @media screen and (min-width: 1024px) {
    top: calc(100% + 0.25rem);
    left: calc(50% - 11rem);
  }

  &.active {
    transition: 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    opacity: 1;
    transform: scale(1);
    pointer-events: initial;
  }

  & > .label {
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    font-weight: 500;
    color: $color-primary;
    font-size: 0.75rem;
    margin-bottom: 0;
  }

  .h3 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    &:after {
      top: 120%;
      width: 7rem;
      left: calc(50% - 3.5rem);
    }
  }

  button {
    width: 100%;
  }
}

@import "navbar";

.btn.--favorite {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fff;
  border-radius: 999rem;

  img {
    width: 1.25rem;
    height: auto;
  }
}

input[type="text"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
textarea {
  min-height: 3rem;
  border-radius: 0.125rem;
  border: 1px solid $color-border;
  padding: 0.75rem 1rem;
  font-size: inherit;
  color: $color-text;
  width: 100%;

  &::placeholder {
    color: $color-placeholder;
  }

  &:focus {
    outline: 0;
  }
}

input[type="search"] {
  border: 0;
  width: 80%;
}

.select2-container--default .select2-selection--single {
  min-height: 3rem;
  border-radius: 0.125rem !important;
  border: 1px solid $color-border !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: $color-text !important;
  line-height: 3rem !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 3rem !important;
}
.select2-dropdown {
  border-color: $color-border !important;
}
.select2-search--dropdown .select2-search__field {
  min-height: initial;
  height: 2rem;
  border-radius: 0.125rem !important;
  border: 1px solid $color-border !important;
}

.banner {
  background-position: center;
  background-size: cover;

  & > * {
    display: flex;
    min-height: 55vh;
    align-items: center;
  }

  h1 {
    color: $color-text-white;

    @media screen and (min-width: 990px) {
      max-width: 55%;
    }
  }
}

.bannerTop {
  background: $color-background-tinted;
  padding: 0.5rem 1rem;

  @media screen and (max-width: 24rem) {
    padding: 0.25rem 1rem;
  }

  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    position: relative;
    top: -1px;
    margin-right: 0.5rem;
    width: 1.5rem;
  }

  span {
    &:last-child {
      display: none;

      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-left: 100px;
      }
    }
  }
}

.notice {
  background: $color-background-alt;
  padding: 1rem 1.5rem;
  text-align: center;
  grid-column: span 2;
  margin-bottom: 1rem;

  @media screen and (max-width: 56rem) {
    font-size: 0.875rem;
    padding: 0.75rem 0.75rem;
  }

  img {
    margin: 0 0.75rem;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.--error {
    background: $color-background-error;
    color: $color-text-error;
  }

  &.--success {
    background: $color-background-success;
    color: $color-text-success;
  }

  &.--notice {
    background: $color-background-notice;
    color: $color-text-notice;
  }

  &.--header {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.tag {
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  background: $color-primary;
  color: $color-white;
  font-weight: 500;
  font-size: 0.9375rem;
}

table {
  width: 100%;
  border-collapse: collapse;

  tr:nth-child(odd) td {
    background-color: $color-background-tinted;
  }

  td {
    padding: 0.75rem 1rem;
    font-weight: 500;
  }
}

.woocommerce:not(body) {
  padding: 2.5rem 0;

  & > .breadcrumbs {
    margin-top: -2.5rem;
  }
}

.content {
  h1 {
    margin-top: 0;
    font-size: 2rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  & > * + * {
    margin-top: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .wp-block-image {
    margin-left: 0;
    margin-right: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.newsletter .gform_footer input[type="submit"] {
  background-color: #009b66;
  padding: 0.75rem 1.5rem;
  border-radius: 0.125rem;
  transition: background-color 0.2s ease-out;
  min-height: 3rem;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.8125rem;
  letter-spacing: 0.0625em;
  font-weight: 500;
  border: none;
}

.grecaptcha-badge
{
    display: none;
}