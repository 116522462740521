.slider.price {
  display: block;
}
.berocket_aapf_widget {
  margin: 0 !important;
  padding: 0;
}
.product-filter > * {
  & > * {
    padding: 0;
  }

  .berocket_aapf_widget-wrapper > ul {
    padding-bottom: 1.5rem;
  }
}

.berocket_single_filter_widget_26 .berocket_aapf_widget-wrapper > ul {
  padding-bottom: 2.5rem;
}

.berocket_aapf_widget-title {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: none;
}

/* Color filters */
.berocket_checkbox_color .berocket_label_widgets {
  margin: 0 !important;
}

span.berocket_color_span_block {
  overflow: hidden !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  border: 0 !important;
  border-radius: 2px;
}

.brw-kleur-wit span.berocket_color_span_block {
  border: 1px solid $color-border !important;

  .berocket_color_span_absolute {
    background-image: url("../images/icon-check.svg");
  }
}

.berocket_aapf_widget-wrapper.brw-color {
  ul {
    display: flex;
    flex-direction: column;
  }
}

.berocket_aapf_widget .berocket_checkbox_color {
  height: 2rem !important;

  label > span {
    transform: unset !important;
    position: relative;
  }
  label.berocket_checked .berocket_color_span_absolute {
    background-size: 1rem;
  }
}

.product-filter
  .berocket_aapf_widget
  .berocket_checkbox_color
  .berocket_label_widgets {
  display: flex !important;
  align-items: center !important;
}

.berocket_color_span_absolute {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  display: block;
  background-image: url("../images/icon-check-white.svg");
  background-position: center;
  background-size: 0rem;
  background-repeat: no-repeat;
}

.berocket_color_text {
  margin-left: 0.5rem !important;
  font-size: 0.9375rem;
  display: inline-block;
}

/* Price filter */
.product-filter .berocket_aapf_widget li.slider div.slide {
  padding: 2rem 1rem 0 1rem;
}

.product-filter .slide.default .berocket_filter_slider .ui-state-default,
.product-filter .slide.default .berocket_filter_price_slider .ui-state-default,
.product-filter
  .slide.default
  .berocket_filter_slider.ui-widget-content
  .ui-state-default,
.product-filter
  .slide.default
  .berocket_filter_price_slider.ui-widget-content
  .ui-state-default,
.product-filter
  .slide.default
  .berocket_filter_slider
  .ui-widget-header
  .ui-state-default,
.product-filter
  .slide.default
  .berocket_filter_price_slider
  .ui-widget-header
  .ui-state-default,
.product-filter .berocket_filter_slider.ui-widget-content .ui-slider-handle,
.product-filter
  .berocket_filter_price_slider.ui-widget-content
  .ui-slider-handle {
  background: #fff;
  width: 1.5rem;
  height: 1.5rem;
  top: calc(-0.75rem + 1px);

  &:focus {
    outline: 0;
  }
}

.ui-widget.ui-widget-content {
  border: 0;
  background-color: $color-background-tinted;
}
.product-filter .berocket_aapf_widget li.slider {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  span.left,
  span.right {
    padding: 0 0.5rem;

    input {
      font-style: normal;
      width: 100%;
      font-size: 0.9375rem;
    }
  }
}

.berocket_aapf_widget[data-type="checkbox"] {
  li {
    margin-bottom: 0.5rem;

    input[type="checkbox"] {
      display: none;

      &:checked ~ label:after {
        background: url("../images/icon-check-white.svg") #000000;
        background-position: center;
        background-repeat: no-repeat;
        border-color: #000000;
      }
    }
    label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row-reverse;
      font-size: 0.9375rem;

      &:after {
        content: "";
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 0.125rem;
        background: url("../images/icon-check-white.svg") #ffffff;
        background-size: 1rem;
        background-position: center;
        background-repeat: no-repeat;
        display: block !important;
        border: 1px solid $color-border;
        margin-right: 0.75rem;
      }
    }
  }
}

.berocket_aapf_widget-title_div {
  background: $color-background-tinted;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .berocket_aapf_widget_show {
    right: 0.5rem !important;
    top: calc(50% - 0.75rem) !important;
  }

  & > * {
    margin: 0 !important;
  }

  h3 {
    text-transform: uppercase;
    font-size: 0.8125rem;
    letter-spacing: 0.0625em;
    font-weight: 500;
  }
}

.berocket_single_filter_hidden .widget_berocket_aapf_single {
  padding: 0;
  border: 0;
}
