@import "variables";

.lookbook {
  background: $color-primary;
  margin-bottom: -3rem;
  padding: 3rem 0;

  @media screen and (min-width: 64rem) {
    padding: 5rem 0;
  }

  header {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .tagline {
    color: $color-white;
    text-transform: uppercase;
    font-size: 0.8125rem;
    letter-spacing: 0.0625em;
    font-weight: 500;
    opacity: 0.7;
  }

  h1 {
    color: #fcd91d;
    font-size: 2rem;
    margin: 0.5rem 0 0 0;

    @extend .hasScribblyLine;

    &:after {
      filter: invert(1);
      width: 7rem;
      left: calc(50% - 3.5rem);
      top: 120%;
      opacity: 0.5;
    }
  }
}

.instagram {
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    width: 100%;
    gap: 2rem;

    li {
      background: #007b51;
    }

    .caption {
      padding: 1.5rem;
      color: $color-white;
      line-height: 1.8;
      word-break: break-word;
    }
  }
}
