.gallery__slider {
  height: auto;
  overflow-x: hidden;
  user-select: none;
  padding: 0.5rem;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E0E0E0' stroke-width='2' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    opacity: 1;
    transition: opacity 0.15s;
    pointer-events: none;
    z-index: -1;
  }
}

.gallery__imageList {
  display: flex;
  touch-action: pan-x;

  > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    object-fit: contain;
    object-position: center;
    pointer-events: none;
  }
}

.gallery__thumbs {
  height: 4rem;
  overflow-x: scroll;
  display: flex;
  margin: 0.5rem 0;
  position: relative;
  -ms-overflow-style: none;
  touch-action: pan-x;

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    height: 100%;
    flex-shrink: 0;
    width: 16%;
    background-size: cover;
    background-position: center;
    margin-right: 0.5rem;
    cursor: pointer;
  }
}
