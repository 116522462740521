.form {
  input[type="text"],
  input[type="search"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  textarea {
    padding: 0.75rem 0.875rem;
    border-radius: 0.125rem;
    -webkit-appearance: none;
    border: 1px solid $color-border;
    width: 100%;
    background: transparent;

    &::placeholder {
      color: $color-placeholder;
    }

    &:hover {
      border-color: darken($color-border, 10%);
    }

    &:focus {
      outline: 0;
      border-color: $color-primary;
    }
  }

  .control {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    label {
      font-weight: 500;
      margin-bottom: 0.25rem;
    }

    label + a {
      margin-left: auto;
      color: $color-label;
      text-decoration: underline;
      margin-bottom: 0.125rem;
    }
  }
}
