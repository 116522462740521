.pagination {
    display: flex;
    justify-content: center;
	align-items: center;
	margin-top: 2rem;

    .page-numbers {
        position: relative;
        display:flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        background-color: transparent;
        color: $black;
        font-weight: $font-weight-normal;
		@include font-size(18);
        text-decoration: none;
        border: 1px solid $gray-300;
        margin: 0 .1rem;

        &:hover {
            color: $secondary;
            border: 1px solid $black;
        }

        &.current {
            background-color: $color-background-alt;
        }
    }

    .prev,
    .next {
        position: relative;
        display:flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        background-color: $color-sale;
        transition: all .2s ease-in-out;

        i {
            font-size: .8rem;
            font-weight: 400;
            color: $black;
        }

        &:hover {
            background-color: $color-primary;
            transition: all .2s ease-in-out;

            i {
                color: $white;
            }
        }
    }
}