.breadcrumbs {
  // padding: 1.5rem 0;
  margin-bottom: 2rem;
  // border-bottom: 1px solid $color-border-light;
  & > * {
    display: none;
  }

  a,
  .breadcrumb_last {
    color: $color-copy;
    font-size: 0.75rem;
  }

  a {
    position: relative;
    padding-right: 0.75rem;
    margin-right: 0.75rem;
    display: inline-flex;
    align-items: center;
    transition: color 0.15s ease;

    &:after {
      content: "";
      background-image: url("../images/icon-chevron-right.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 6px;
      height: 8px;
      position: absolute;
      left: calc(100% - 2px);
    }

    &:hover {
      color: $color-black;
    }
  }

  .breadcrumb_last {
    text-decoration: none;
  }
}
