.auth {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  gap: 2rem;
  padding: 3rem 0;

  @media screen and (max-width: 32rem) {
    grid-template-columns: 1fr;
  }

  > * {
    padding: 2rem;
    border: 1px solid $color-border;
    border-radius: 0.125rem;
  }

  .notice {
    grid-row: span 2;
  }
}

.notice.--favorite {
  background: #fff;
  display: grid;
  grid-template-columns: 7rem 1fr;
  text-align: left;
  gap: 0 1.5rem;
  padding: 1rem;
  border-radius: 0.125rem;

  p {
    margin: 0;

    &:nth-child(2) {
      align-self: flex-end;
    }

    &:nth-child(3) {
      align-self: flex-start;
    }
  }

  img {
    grid-row: span 2;
    margin: 0;
    border-radius: 0.125rem;
  }
}

.account {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0;
}
.account__navigation {
  flex-grow: 1;
  border-radius: 0.125rem;
  margin-right: 2rem;
  margin-bottom: 2rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      padding: 0.5rem 1rem;
      display: flex;
      border-radius: 0.125rem;
    }

    li:last-child a {
      border-bottom: 0;
    }

    li.is-active a {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}
.account__content {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(75% - 2rem);
}

.no-orders {
  padding: 4rem 4rem 3rem;
  background: $color-background-tinted;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    color: $color-copy;
    font-size: 0.9375rem;
    text-decoration: underline;

    &:hover {
      text-decoration-style: dashed;
    }
  }
}

.register__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.woocommerce-ResetPassword {
  width: 100%;
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid $color-border;
  border-radius: 0.125rem;
  padding: 1rem 2rem;

  .woocommerce-Button {
    @extend .btn;
    @extend .--primary;
  }
}
